<template>
  <!-- side popup modal -->
  <form
    method="post"
    @submit.prevent="submitHandler()"
    enctype="multipart/form-data"
  >
    <div class="form-field-group">
      <h3 class="form-title">Import Billing</h3>
      <div class="row grid-1">
        <div class="col form-group d-flex upload-wrap">
          <label class="form-label" for="type">Upload csv</label>
          <div>
            <div class="upload-file-field">
              <input
                class="form-control"
                type="file"
                ref="documents"
                @change="uploadDocuments()"
                :accept="`.${allowFileTypes.join(', .')}`"
              />
              <label class="placeholder">Upload csv</label>
              <span class="field-icon"
                ><font-awesome-icon :icon="['fa', 'cloud-upload-alt']" />
                UPLOAD</span
              >
            </div>
            <span
              v-if="submitted && v$.import_file.required.$invalid"
              class="error"
              >* required.</span
            >
            <div class="uploaded-doc">
              <template v-for="(document, index) in import_file" :key="index">
                <div class="uploaded-docoments">
                  <a :href="`${document.path}`" target="_blank">{{
                    document.name
                  }}</a>
                  <a
                    href="javascript:void(0);"
                    @click.prevent="removeDocument(index)"
                    ><span class="lnr lnr-cross-circle text-danger"></span
                  ></a>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="form-btns">
        <div class="d-flex justify-content-end">
          <button
            class="secondary-btn"
            title="Cancel"
            type="button"
            @click.prevent="$emit('close')"
          >
            Cancel
          </button>
          <button
            class="primary-btn"
            title="Update"
            type="submit"
            @click.prevent="submitHandler()"
          >
            Import
          </button>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
library.add(faCloudUploadAlt);
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import common from "../mixins/common";
import billingService from "../services/billing.service";

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      uploadPath: process.env.VUE_APP_UPLOAD_BASE_PATH,
      allowFileTypes: ["csv"],
      import_file: null,
      submitted: false,
    };
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  components: { FontAwesomeIcon },
  mixins: [common],
  validations() {
    return {
      import_file: { required },
    };
  },
  methods: {
    async submitHandler() {
      let vm = this;

      try {
        vm.submitted = true;
        vm.v$.$touch();

        const isValidate = await vm.v$.$validate();

        if (!isValidate) return;

        let formData = new FormData();

        formData.append("uproster_profile_id", vm.profile.Id);
        formData.append("import_file", vm.import_file[0]);

        vm.$store.commit("loadingStatus", true);
        await vm.$store.dispatch("getUserDetails").then(async(response) => {
        if(response.is_active){
            const response = await billingService.import(formData);
            vm.toastMessage(response.message, "success");
          }
        });
        vm.$emit("close");
        vm.$emit("billingImported");
        vm.$store.commit("loadingStatus", false);
      } catch (error) {
        const message = vm.errorMessage(error);
        vm.toastMessage(message, "error");
        vm.$store.commit("loadingStatus", false);
      }
    },
    uploadDocuments() {
      let vm = this;
      const files = vm.$refs.documents.files;
      const finalFile = Array.from(files);

      for (let i = 0; i < finalFile.length; i++) {
        let ext = finalFile[i].name.split(".").pop();
        if (!vm.allowFileTypes.includes(ext)) {
          vm.toastMessage(
            `The documents must be a file of type: ${vm.allowFileTypes.join(
              ", "
            )}`,
            "error"
          );
          finalFile.splice(i, 1);
        }

        finalFile[i].path = URL.createObjectURL(finalFile[i]);

        if (finalFile[i].size > 102400 * 7) {
          vm.toastMessage("Document should be less or equal to 7MB.", "error");
          finalFile.splice(i, 1);
        }
      }

      if (finalFile.length > 0) {
        vm.import_file = finalFile;
      }
    },
    removeDocument(index) {
      let vm = this;
      vm.import_file.splice(index, 1);
    },
  },
};
</script>
