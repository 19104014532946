<template>
  <div class="profile-data-wrapper" v-show="!loading">
    <template
      v-if="
        userPermissions &&
        userPermissions.contracts &&
        userPermissions.contracts.length > 0 &&
        userPermissions.contracts.includes('View')
      "
    >
      <div class="profile-options">
        <div class="row">
          <div class="col">
            <router-link class="btn primary-btn" :to="{ name: 'ProfileRoster' }"
              ><font-awesome-icon :icon="['fas', 'arrow-left']"
            /></router-link>
            <a
              class="btn primary-btn"
              href="javascript:void(0);"
              @click.prevent="openSideModal('createContract')"
              v-if="userPermissions.contracts.includes('Add')"
              >Add new Contract</a
            >
            <!-- <a
              class="btn primary-btn"
              href="javascript:void(0);"
              @click.prevent="openSideModal('createBilling')"
              v-if="
                activeContractCount > 0 &&
                userPermissions.billing &&
                userPermissions.billing.length > 0 &&
                userPermissions.billing.includes('View')
              "
              >Add Billing</a
            > -->
          </div>

          <div class="col flex-grow-1 d-flex justify-content-end">
            <div class="search-container">
              <input
                class="form-control--filled"
                name="search"
                @keyup="performSearch"
                v-model.trim="searchquery"
                placeholder="Search By Contract ID, Client, Description.."
                type="text"
              />
              <button type="submit">
                <span class="lnr lnr-magnifier"></span>
              </button>
            </div>
          </div>
          <div class="col">
            <Select2
              id="filter-contract-types"
              v-model="filters.contractType"
              :options="['Hourly', 'Fixed Price']"
              placeholder="All Contract Types"
              class="select2-box"
              @select="performSearch"
            />
          </div>
          <div class="col">
            <Select2
              id="filter-profile-skills"
              v-model="filters.contractStatus"
              :options="['Active', 'Ended', 'Suspended']"
              placeholder="All Status"
              class="select2-box"
              @select="performSearch"
            />
          </div>
        </div>
      </div>
      <div class="profile-table custom-table">
        <table class="table" id="datatable">
          <thead>
            <tr>
              <th>Contract ID</th>
              <th>Client</th>
              <th>Contract Desc.</th>
              <th>Type</th>
              <th>Status</th>
              <th>Rate ($)</th>
              <th class="no-sort">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="contract in contracts" :key="contract.Id">
              <td>{{ contract.ContractId }}</td>
              <td>{{ contract.ContactPerson }}</td>
              <td>{{ contract.Title }}</td>
              <td>{{ contract.ContractType }}</td>
              <td>{{ contract.Status }}</td>
              <td>
                <template v-if="contract.ContractType === 'Hourly'">{{
                  contract.HourlyRate > 0
                    ? `${$filters.currencyUSD(contract.HourlyRate)} /hr`
                    : ""
                }}</template>
                <template v-if="contract.ContractType === 'Fixed Price'">{{
                  contract.FixedPriceAmountAgreed > 0
                    ? `${$filters.currencyUSD(contract.FixedPriceAmountAgreed)}`
                    : ""
                }}</template>
              </td>
              <td>
                <div>
                  <ul class="d-flex">
                    <li v-if="userPermissions.contracts.includes('Update')">
                      <a
                        class="custom-tooltip"
                        href="javascript:void(0);"
                        @click.prevent="openSideModal('editContract', '', contract.Id)"
                        v-tippy="`Edit Contract`"
                        v-if="['Active', 'Suspended'].includes(contract.Status)"
                        ><span class="lnr lnr-pencil"></span
                      ></a>
                    </li>
                    <li v-if="userPermissions.contracts.includes('View')">
                      <a
                        class="custom-tooltip"
                        href="javascript:void(0);"
                        @click.prevent="openSideModal('viewContract', '', contract.Id)"
                        v-tippy="`View Contract`"
                      >
                        <span class="lnr lnr-eye"></span>
                      </a>
                    </li>
                    <li v-if="userPermissions.contracts.includes('Delete')">
                      <a
                        class="custom-tooltip"
                        href="javascript:void(0);"
                        @click.prevent="deleteContract(contract.Id)"
                        v-tippy="`Delete Contract`"
                      >
                        <span class="lnr lnr-trash"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="profile-footer d-flex justify-content-end">
        <button
          class="primary-btn"
          v-if="contracts && isLoaded"
          type="button"
          @click="downloadCsv()"
        >
          <i class="fas fa-download"></i>Download
        </button>
      </div>
      <SideModal
        @close="closeSideModal"
        v-bind="{
          contentClass: {
            'sm-side--modal':
              contract.show ||
              contract.import.show ||
              contract.billing.show ||
              contract.billing.import.show,
            'contract-form': contract.manual.show,
          },
        }"
      >
        <template v-slot:header>
          <template v-if="contract.show">CREATE A NEW CONTRACT</template>
          <template v-if="contract.view.show"
            >VIEW CONTRACT ({{ contract.profile.Name }})</template
          >
          <template v-if="contract.manual.show"
            ><template v-if="contract.profile.contractId">EDIT</template
            ><template v-else>CREATE</template> CONTRACT ({{
              contract.profile.Name
            }})</template
          >
          <template v-if="contract.import.show"
            >IMPORT CONTRACT ({{ contract.profile.Name }})</template
          >
          <template v-if="contract.billing.show"
            >Add Billing ({{ contract.profile.Name }})</template
          >
          <template v-if="contract.billing.import.show"
            >IMPORT Billing ({{ contract.profile.Name }})</template
          >
        </template>
        <template v-slot:body>
          <!-- contract -->
          <div class="new-record--wrap text-center" v-if="contract.show">
            <a
              href="javascript:void(0);"
              class="secondary-btn w-100"
              v-on:click.prevent="openSideModal('createContract', 'manual')"
              >create a new contract manually</a
            >
            <div class="seprater">or</div>
            <a
              href="javascript:void(0);"
              class="primary-btn w-100"
              v-on:click.prevent="openSideModal('createContract', 'import')"
              >import data</a
            >
          </div>
          <add-edit-contract
            v-if="contract.manual.show"
            :profile="contract.profile"
            @close="closeSideModal"
            @refreshContracts="getContracts()"
          ></add-edit-contract>
          <view-contract
            v-if="contract.view.show"
            :profile="contract.profile"
            @close="closeSideModal"
            @refreshContracts="getContracts()"
          ></view-contract>
          <import-contract
            v-if="contract.import.show"
            :profile="contract.profile"
            @close="closeSideModal"
            @contractImported="getContracts()"
          ></import-contract>

          <!-- Billing -->
          <div class="new-record--wrap text-center" v-if="contract.billing.show">
            <router-link
              :to="{ name: 'ProfileRosterBilling' }"
              class="secondary-btn w-100"
              >Add billing manually</router-link
            >
            <div class="seprater">or</div>
            <a
              href="javascript:void(0);"
              class="primary-btn w-100"
              v-on:click.prevent="openSideModal('createBilling', 'import')"
              >import data</a
            >
          </div>

          <import-contract-billing
            v-if="contract.billing.import.show"
            :profile="contract.profile"
            @close="closeSideModal"
            @billingImported="getContracts()"
          ></import-contract-billing>
        </template>
      </SideModal>
    </template>
    <template
      v-if="
        userPermissions &&
        userPermissions.contracts &&
        (userPermissions.contracts.length === 0 ||
          (userPermissions.contracts.length > 0 &&
            !userPermissions.contracts.includes('View')))
      "
      ><AccessDenied/></template
    >
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

library.add(faArrowLeft);

import AddEditContract from "../components/AddEditContract.vue";
import ViewContract from "../components/ViewContract.vue";
import ImportContract from "../components/ImportContract.vue";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.html5.js";
import $ from "jquery";
import SideModal from "../components/SideModal.vue";
import common from "../mixins/common";
import contractService from "../services/contract.service";
import ImportContractBilling from "../components/ImportContractBilling.vue";
import Select2 from "../components/Select2.vue";
import AccessDenied from "../components/AccessDenied.vue"

export default {
  name: "ProfileRosterContracts",
  data: () => ({
    loading: true,
    filters: {
      contractType: "",
      contractStatus: "",
    },
    activeContractCount: 0,
    contract: {
      show: false,
      profile: {
        Id: "",
        Name: "",
        contractId: 0,
        contractData: {},
        HourlyRate: "",
      },
      manual: {
        show: false,
      },
      import: {
        show: false,
      },
      view: {
        show: false,
      },
      billing: {
        show: false,
        manual: {
          show: false,
        },
        import: {
          show: false,
        },
      },
    },
    searchquery: null,
    contracts: [],
    isLoaded: false,
  }),
  components: {
    FontAwesomeIcon,
    SideModal,
    AddEditContract,
    ViewContract,
    ImportContract,
    ImportContractBilling,
    Select2,
    AccessDenied
  },
  mixins: [common],
  methods: {
    async openSideModal(action, method = "", contractId = 0) {
      let vm = this;

      await vm.$store.dispatch("getUserDetails");

      vm.contract.show = false;
      vm.contract.profile.contractId = contractId;
      vm.contract.profile.contractData = {};

      if (
        vm.userPermissions &&
        vm.userPermissions.contracts &&
        vm.userPermissions.contracts.length > 0 &&
        vm.userPermissions.contracts.includes("View")
      ) {
        if (action === "createContract" && vm.userPermissions.contracts.includes("Add")) {
          if (method === "manual") {
            document.body.classList.remove("lmodal-open");
            setTimeout(() => {
              vm.contract.show = false;
              vm.contract.manual.show = true;
              document.body.classList.add("lmodal-open");
            }, 300);
          } else if (method === "import") {
            document.body.classList.remove("lmodal-open");

            setTimeout(() => {
              vm.contract.show = false;
              vm.contract.import.show = true;
              document.body.classList.add("lmodal-open");
            }, 300);
          } else {
            vm.contract.show = true;
            document.body.classList.add("lmodal-open");
          }
        } else if (
          action === "editContract" &&
          vm.userPermissions.contracts.includes("Update")
        ) {
          vm.$store.commit("loadingStatus", true);

          await contractService
            .getContractData(contractId)
            .then((response) => {
              delete response.data.created_at;
              delete response.data.updated_at;
              // console.log(response.data);
              vm.contract.profile.contractData = response.data;
              setTimeout(() => {
                vm.contract.show = false;
                vm.contract.manual.show = true;
                document.body.classList.add("lmodal-open");
              }, 300);
            })
            .catch((error) => {
              const message = vm.errorMessage(error);
              vm.toastMessage(message, "error");
            })
            .finally(() => {
              vm.$store.commit("loadingStatus", false);
            });
        } else if (
          action === "viewContract" &&
          vm.userPermissions.contracts.includes("View")
        ) {
          vm.$store.commit("loadingStatus", true);

          await contractService
            .getContractData(contractId)
            .then((response) => {
              delete response.data.created_at;
              delete response.data.updated_at;
              // console.log(response.data);
              vm.contract.profile.contractData = response.data;
              setTimeout(() => {
                vm.contract.show = false;
                vm.contract.view.show = true;
                document.body.classList.add("lmodal-open");
              }, 300);
            })
            .catch((error) => {
              const message = vm.errorMessage(error);
              vm.toastMessage(message, "error");
            })
            .finally(() => {
              vm.$store.commit("loadingStatus", false);
            });
        } else if (
          action === "createBilling" &&
          vm.userPermissions.contracts.includes("View")
        ) {
          if (method === "manual") {
            document.body.classList.remove("lmodal-open");
            setTimeout(() => {
              vm.contract.billing.show = false;
              vm.contract.billing.manual.show = true;
              document.body.classList.add("lmodal-open");
            }, 300);
          } else if (method === "import") {
            document.body.classList.remove("lmodal-open");

            setTimeout(() => {
              vm.contract.billing.show = false;
              vm.contract.billing.import.show = true;
              document.body.classList.add("lmodal-open");
            }, 300);
          } else {
            vm.contract.billing.show = true;
            document.body.classList.add("lmodal-open");
          }
        }
      }
    },
    closeSideModal() {
      let vm = this;
      vm.$store.dispatch("getUserDetails");
      document.body.classList.remove("lmodal-open");
      setTimeout(() => {
        this.contract.show = false;
        this.contract.manual.show = false;
        this.contract.import.show = false;
        this.contract.view.show = false;
        this.contract.billing.show = false;
        this.contract.billing.manual.show = false;
        this.contract.billing.import.show = false;
      }, 300);
    },
    async getContracts() {
      let vm = this;
      //await vm.$store.dispatch("getUserDetails");
      const userPermissions = await vm.userPermissions;
      if (
        userPermissions &&
        userPermissions.contracts &&
        userPermissions.contracts.length > 0
      ) {
        try {
          const profileId = vm.$route.params.ID;
          vm.$store.commit("loadingStatus", true);
          const response = await contractService.getContractsByProfileId(profileId);

          vm.activeContractCount = response.profile.contracts_count;

          vm.contracts = response.contracts;
          vm.contract.profile.Name = response.profile.ProfileName;
          vm.contract.profile.Id = response.profile.Id;
          vm.contract.profile.HourlyRate =
            response.profile.profile_professional_detail &&
            response.profile.profile_professional_detail.HourlyRate
              ? response.profile.profile_professional_detail.HourlyRate
              : "";
          vm.$store.commit("username", response.profile.ProfileName);

          var dT = $("#datatable").DataTable();
          dT.destroy();

          vm.$nextTick(function () {
            $("#datatable").DataTable({
              dom: "Bfrtip",
              buttons: [
                {
                  extend: "csv",
                  filename: "Profile Roster",
                  extension: ".csv",
                  exportOptions: {
                    columns: "th:not(:last-child)",
                  },
                },
              ],
              iDisplayLength: 25,
              bLengthChange: false,
              /* Disable initial sort */
              aaSorting: [],
              columnDefs: [
                { orderable: false, targets: [5] },
                { orderable: true, targets: [0, 1, 2, 3, 4] },
              ],
              info: false,
              fnDrawCallback: function (oSettings) {
                if (oSettings._iDisplayLength >= oSettings.fnRecordsDisplay()) {
                  $(oSettings.nTableWrapper).find(".dataTables_paginate").hide();
                } else {
                  $(oSettings.nTableWrapper).find(".dataTables_paginate").show();
                }
              },
            });
            $(".dt-buttons, .dataTables_filter").hide();
            this.isLoaded = true;

            this.performSearch();
          });
          vm.$store.commit("loadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      }
    },
    downloadCsv: async function () {
      let vm = this;
      await vm.$store.dispatch("getUserDetails").then(response => {
        if(response.is_active) $(".buttons-csv").click();
      });
    },
    performSearch: function () {
      var oTable = $("#datatable").dataTable();
      //console.log("test", oTable);
      var searchText = " ";
      if (this.searchquery != null) {
        searchText += this.searchquery;
      }

      if (this.filters.contractType != null) {
        searchText += " " + this.filters.contractType;
      }

      if (this.filters.contractStatus != null) {
        searchText += " " + this.filters.contractStatus;
      }
      //oTable.fnFilter(searchText);
      if (this.filters.contractStatus != "") {
        oTable.fnFilter(searchText, null, true, true, true, false);
      } else {
        //oTable._fnClearTable();
        oTable._fnDraw();
        oTable.fnFilter(searchText);
      }
    },
    beforeUnmount() {
      this.$store.commit("username", "");
    },
    async deleteContract(Id) {
      let vm = this;
      await vm.$store.dispatch("getUserDetails").then(async(response) => {
          if(response.is_active) {
            await vm
              .$swal({
                title: "Delete Contract",
                text: "Are you sure you want to delete this Contract?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#43832a",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
              })
              .then(async function (result) {
                if (result.isConfirmed) {
                  try {
                    vm.$store.commit("loadingStatus", true);
                    vm.$store.dispatch("getUserDetails")
                    await vm.$store.dispatch("getUserDetails").then(async(response) => {
                      if(response.is_active) {
                        await contractService.destroy(Id);
                        vm.getContracts();
                      }
                    });
                    vm.$store.commit("loadingStatus", false);
                  } catch (error) {
                    const message = vm.errorMessage(error);
                    vm.toastMessage(message, "error");
                    vm.$store.commit("loadingStatus", false);
                  }
                }
              });
          }    
      });
    },
  },
  async mounted() {
    this.$store.commit("username", "");
    await this.getContracts();
    this.loading = false;
  },
  watch: {
    userPermissions: {
      handler: function () {
        this.getContracts();
      },
      deep: true,
    },
  },
};
</script>
